import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import he from 'he';

const WorkItems = ({ data }) => {
  let items = data.allWordpressWpFirewoodPortfolio.edges;

  // Test more items
  /*items = items.reduce(function (res, current, index, array) {
    return res.concat([current, current]);
  }, []);
  items.pop();
  items.pop();*/

  let totalItems = items.length;
  let remainder = totalItems % 5;

  return (
    <div>
      <div className="section-work">
        <div className="work-items">
          {items.length > 0 &&
            items.map((item, j) => {
              let thisItem = item.node;
              let backgroundImage =
                item.node.featured_media.localFile.childCloudinaryAsset.fluid;

              // Add classes if number of items is NOT multiple of 5
              let itemClass = 'item';
              if (remainder !== 0) {
                if (j === totalItems - 1) {
                  if (remainder === 1 || remainder === 4) {
                    itemClass = 'item item--full-width';
                  }
                  if (remainder === 2) {
                    itemClass = 'item item--full-height';
                  }
                }
              }

              return (
                <Link
                  key={j}
                  className={itemClass}
                  to={`/work/${thisItem.slug}/`}
                >
                  <div className="link">
                    {he.decode(thisItem.title)}{' '}
                    <i className="fas fa-arrow-right" />
                  </div>
                  <div className="overlay" />
                  {backgroundImage && (
                    <div className="background-image">
                      <Img fluid={backgroundImage} />
                    </div>
                  )}
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      {
        allWordpressWpFirewoodPortfolio {
          edges {
            node {
              slug
              title
              featured_media {
                localFile {
                  childCloudinaryAsset {
                    fluid(maxWidth: 1000) {
                      ...CloudinaryAssetFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <WorkItems data={data} {...props} />}
  />
);
