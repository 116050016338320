import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../../layouts";
import FlexibleContent from "../../components/FlexibleContent";
import WorkItems from "../../components/WorkItems";
import SEO from '../../components/seo/SEO';
import { pageTitle } from "../../utils/helpers";
import { useSiteMetadata } from "../../hooks/use-site-metadata";

export default function Work( props ) {
  
  const currentPage = props.data.wordpressPage;
  const site = useSiteMetadata();

  const currentPageTitle = (currentPage.yoast_meta.yoast_wpseo_title) ? currentPage.yoast_meta.yoast_wpseo_title : pageTitle({
    site: site.title,
    page: currentPage
  });

  return (
    <Layout>
      <Helmet bodyAttributes={{ id: `page-${currentPage.slug}` }}>
        <title>
          {currentPageTitle}
        </title>
      </Helmet>
      <SEO node={currentPage} site={site} />
      <FlexibleContent currentPage={currentPage} />
      <WorkItems />
    </Layout>
  );
}

export const pageQuery = graphql`
  {
    wordpressPage(title: { eq: "Work" }) {
      title
      slug
      ...Interstitial
      ...SEO
    }
  }
`;
